import React from "react";
import { ReactComponent as LivletLogo } from "../images/livlet-logo-footer.svg";
import { ReactComponent as FacebookLogo } from "../images/facebook.svg";
import { ReactComponent as InstagramLogo } from "../images/instagram.svg";
import { ReactComponent as LinkedinLogo } from "../images/linkedin.svg";
import { ReactComponent as TwitterLogo } from "../images/twitter.svg";
import envConfig from "../config";

const Footer = () => {
  return (
    <div className={"stripe-dark-gray footer-stripe"} style={{padding: 0}}>
      <div className={"container footer"}>
        <div className={"footer-column"}>
          <LivletLogo />
        </div>
        <div className={"footer-column"}>
          Livlet
          <br />239 Kensington High St
          <br />London, W8 6SN UK
          <br />+44 (0) 207 316 3018
          <br /><a href={"mailto:info@livlet.com"}>info@livlet.com</a>
        </div>
        <div className={"footer-column"}>
          <a className={"footer-faq"} href={"/faq"}>FAQs</a>
          <br/><a className={"footer-privacypolicy"} href={`${envConfig.WEBAPP_URL}/privacypolicy`} target={"_blank"} rel={"noreferrer"}>Privacy Policy</a>
          <br/><a className={"footer-terms"} href={`${envConfig.WEBAPP_URL}/terms`} target={"_blank"} rel={"noreferrer"}>Terms and Conditions</a>
        </div>
        <div className={"footer-column"}>
          Follow us
          <br />
          <a href={"https://www.linkedin.com/company/livlet"} target={"_blank"} rel={"noreferrer"}>
            <LinkedinLogo style={{marginTop: "0.5rem", marginRight: "0.5rem"}} />
          </a>
          <a href={"https://www.facebook.com/Livlet-100253142596700"} target={"_blank"} rel={"noreferrer"}>
            <FacebookLogo style={{marginTop: "0.5rem", marginRight: "0.5rem"}} />
          </a>
          <a href={"https://twitter.com/livletuk"} target={"_blank"} rel={"noreferrer"}>
            <TwitterLogo style={{marginTop: "0.5rem", marginRight: "0.5rem"}} />
          </a>
          <a href={"https://www.instagram.com/livletuk/"} target={"_blank"} rel={"noreferrer"}>
            <InstagramLogo style={{marginTop: "0.5rem"}} />
          </a>
        </div>
        {/*<div className={"footer-column"}>*/}
        {/*  <a className={"footer-sign-up"} href={`${envConfig.WEBAPP_URL}/register`} target={"_blank"} rel={"noreferrer"}>*/}
        {/*    <button className={"free-trial-button button-green"}>Sign-up free</button>*/}
        {/*  </a>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default Footer