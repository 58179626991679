const MARKETING_COOKIES = "livlet-marketing-localStorage-cookies"
const COOKIE_PREFERENCES = "livlet-marketing-localStorage-preferences"
const EMAIL_PREFERENCES = "livlet-marketing-email-preferences"

export function getMarketingCookies() {
  return localStorage.getItem(MARKETING_COOKIES);
}

export function setMarketingCookies(triggerValue: string) {
  localStorage.setItem(MARKETING_COOKIES, triggerValue);
}

export function getCookiePreferences() {
  return localStorage.getItem(COOKIE_PREFERENCES);
}

export function setCookiePreferences(triggerValue: string) {
  localStorage.setItem(COOKIE_PREFERENCES, triggerValue);
}

export function getEmailPreferences() {
  return localStorage.getItem(EMAIL_PREFERENCES);
}

export function setEmailPreferences(triggerValue: string) {
  localStorage.setItem(EMAIL_PREFERENCES, triggerValue);
}