import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import homeInformation1 from "../../images/home-graphics/homeInformation1.png";
import homeInformation2 from "../../images/home-graphics/homeInformation2.png";
import homeInformation3 from "../../images/home-graphics/homeInformation3.png";
import homeInformation4 from "../../images/home-graphics/homeInformation4.png";
import homeInformation5 from "../../images/home-graphics/homeInformation5.png";

import records1m from "../../images/home-graphics-mobile/record1.png";
import records2m from "../../images/home-graphics-mobile/record2.png";
import records3m from "../../images/home-graphics-mobile/record3.png";
import records4m from "../../images/home-graphics-mobile/record4.png";
import records5m from "../../images/home-graphics-mobile/record5.png";

export type HomeBlockProps = {
  isDesktop: boolean;
  anchor?: any;
}

const HomeInformation = (props: HomeBlockProps) => {
  const {isDesktop, anchor} = props;

  const desktopVersion = () => (<div className={"square-wrapper-home mobile-reverse"} ref={anchor}>
    <div className={"square square-blue square-left square-graphic"}>
      <div className={"graphic-wrapper"}>
        <ScrollAnimation
          style={{zIndex: 1, gridColumnStart: 1, gridRowStart: 1}}
          animateIn='fadeIn' offset={0}>
          <img src={homeInformation1} style={{maxWidth: "100%"}} alt={""} />
        </ScrollAnimation>
        <ScrollAnimation
          style={{zIndex: 2, gridColumnStart: 1, gridRowStart: 1}}
          animateIn='fadeIn' delay={1000}>
          <img src={homeInformation2} style={{maxWidth: "100%"}} alt={""} />
        </ScrollAnimation>
        <ScrollAnimation
          style={{zIndex: 3, gridColumnStart: 1, gridRowStart: 1}}
          animateIn='fadeIn' delay={1500}>
          <img src={homeInformation3} style={{maxWidth: "100%"}} alt={""} />
        </ScrollAnimation>
        <ScrollAnimation
          style={{zIndex: 5, gridColumnStart: 1, gridRowStart: 1}}
          animateIn='fadeIn' delay={2000}>
          <img src={homeInformation4} style={{maxWidth: "100%"}} alt={""} />
        </ScrollAnimation>
        <ScrollAnimation
          style={{zIndex: 5, gridColumnStart: 1, gridRowStart: 1}}
          animateIn='fadeIn' delay={2500}>
          <img src={homeInformation5} style={{maxWidth: "100%"}} alt={""} />
        </ScrollAnimation>
      </div>
    </div>
    <div className={"square square-blue square-right square-end"}>
      <div className={"square-content home-square-text feature-square"}>
        <div className={"group-tagline"}>HOME INFORMATION</div>
        <div className={"group-header"}>All your important files in one easy to navigate system then <span className={"highlight-bright-green"}>connected intelligently.</span></div>
        <div className={"group-content"}>Collect and sort your documents into the spaces they are relevant to, making important ones easier to find.
          Bills, legal documents, warranties, quotes for works, invoices for services, notes - it can all be stored and linked to give you the best
          insights and make sure nothing is forgotten.</div>
      </div>
    </div>
  </div>)

  const mobileVersion = () => (<div className={"square-wrapper square-wrapper-mobile"} ref={anchor}>
    <div className={"square square-mobile"}>
      <div className={"group-tagline"}>RECORDS</div>
      <div className={"group-header"}>All your records in one place.<span className={"highlight-bright-green"}>connected intelligently.</span></div>
      <div className={"graphic-wrapper-mobile"}>
        <ScrollAnimation
          animateIn='fadeIn' offset={0}>
          <img src={records1m} style={{maxWidth: "100%"}} alt={""} />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn='fadeIn'>
          <img src={records2m} style={{maxWidth: "100%"}} alt={""} />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn='fadeIn'>
          <img src={records3m} style={{maxWidth: "100%"}} alt={""} />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn='fadeIn'>
          <img src={records4m} style={{maxWidth: "100%"}} alt={""} />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn='fadeIn'>
          <img src={records5m} style={{maxWidth: "100%"}} alt={""} />
        </ScrollAnimation>
      </div>
    </div>
  </div>)

  return desktopVersion()
}

export default HomeInformation