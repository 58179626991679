import React from "react"
import { LoadingOutlined } from '@ant-design/icons';
import Spin from 'antd/es/spin';

const SuspensePlaceholder = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  return <div style={{maxWidth: "1440px", margin: "auto", textAlign: "center", padding: "1rem"}}>
    <Spin indicator={antIcon} />
  </div>
}

export default SuspensePlaceholder