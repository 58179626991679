import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

type Props = {
  title: string;
  description: string;
  keywords: string;
  image?: string;
  hashtag?: string;
}

const HelmetMetaData = (props: Props) => {
  const {title, description, keywords, image, hashtag} = props;

  let location = useLocation();
  let currentUrl = "https://livlet.com" + location.pathname;

  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="keywords" content={keywords} />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta property="image" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:hashtag" content={hashtag ?? ""} />
      <meta property="og:image" content={image} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="Livlet" />
      <meta property="og:description" content={description} />
    </Helmet>
  );
}

export default HelmetMetaData;