export interface EnvConfig {
  API_URL: string;
  APP_URL: string;
  WEBAPP_URL: string;
  GOOGLE_RECAPTCHA_SITE_KEY: string;
}

const envConfig: EnvConfig = {
  API_URL: process.env.REACT_APP_API_URL  || "https://uk.livlet.com",
  APP_URL: process.env.REACT_APP_APP_URL || "https://livlet.com",
  WEBAPP_URL: process.env.REACT_APP_WEBAPP_URL || "https://uk.livlet.com",
  GOOGLE_RECAPTCHA_SITE_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || "",
};

export default envConfig;
