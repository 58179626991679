import React, {useState} from "react";
import {Dropdown, Input, Select} from "antd";

import { ReactComponent as ArrowDown } from "../images/ArrowDown.svg";
import { ReactComponent as ArrowDownGray } from "../images/ArrowDownGray.svg";
import { ReactComponent as InfoIcon } from "../images/InfoIcon.svg";

import { ReactComponent as GrowthDown } from "../images/GrowthDown.svg";
import { ReactComponent as GrowthUp } from "../images/GrowthUp.svg";
import { ReactComponent as SimulatorHome } from "../images/SimulatorHome.svg";
import { ReactComponent as SimulatorImprovedHome } from "../images/SimulatorImprovedHome.svg";

import calcHandshower from "../images/calcHandshower.png"
import calcHeadshower from "../images/calcHeadshower.png"
import calcRainshower from "../images/calcRainshower.png"

import HelmetMetaData from "../components/HelmetMetaData";

const ShowerCalculator = () => {
  const hotWaterTemperature = 55
  const coldWaterTemperature = 10
  const density = 1
  const specificWaterHeat = 4.186

  const [numberOfShowers, setNumberOfShowers] = useState(4)
  const [showerLength, setShowerLength] = useState(10)
  const [showerFlowRate, setShowerFlowRate] = useState(12)

  const [possibleNumberOfShowers, setPossibleNumberOfShowers] = useState(4)
  const [possibleLength, setPossibleLength] = useState(8)
  const [possibleFlowRate, setPossibleFlowRate] = useState(8)

  const [isEditing, toggleEditing] = useState(false)
  const [costTypeToShow, setCostTypeToShow] = useState("Annual")
  const [frequencyToShow, setFrequencyToShow] = useState("yr")

  const [targetShowerTemperature, setTargetShowerTemperature] = useState(40)
  const [gasCost, setGasCost] = useState(0.08)
  const [waterCost, setWaterCost] = useState(3.08)
  const [boilerEfficiency, setBoilerEfficiency] = useState(90)

  const [tempShowerTemperature, setTempShowerTemperature] = useState(40)
  const [tempGasCost, setTempGasCost] = useState(0.08)
  const [tempWaterCost, setTempWaterCost] = useState(3.08)
  const [tempBoilerEfficiency, setTempBoilerEfficiency] = useState(90)

  const [annualGasCost, setAnnualGasCost] = useState(543)
  const [annualWaterCost, setAnnualWaterCost] = useState(540)
  const [monthlyGasCost, setMonthlyGasCost] = useState(45)
  const [monthlyWaterCost, setMonthlyWaterCost] = useState(45)
  const [dailyGasCost, setDailyGasCost] = useState(1.488)
  const [dailyWaterCost, setDailyWaterCost] = useState(1.478)

  const [possibleAnnualGasCost, setPossibleAnnualGasCost] = useState(290)
  const [possibleAnnualWaterCost, setPossibleAnnualWaterCost] = useState(288)
  const [possibleMonthlyGasCost, setPossibleMonthlyGasCost] = useState(24)
  const [possibleMonthlyWaterCost, setPossibleMonthlyWaterCost] = useState(24)
  const [possibleDailyGasCost, setPossibleDailyGasCost] = useState(0.794)
  const [possibleDailyWaterCost, setPossibleDailyWaterCost] = useState(0.788)

  const [annualSavings, setAnnualSavings] = useState(505)
  const [monthlySavings, setMonthlySavings] = useState(42)
  const [dailySavings, setDailySavings] = useState(1.384)

  const infoDropdown = (title: string, description: any) => (
    <Dropdown overlay={<div className={"calc-dropdown"}>
      <div style={{fontWeight: 600, marginBottom: "0.5rem"}}>{title}</div>
      {description}
    </div>}><InfoIcon style={{marginLeft: "0.25rem"}} /></Dropdown>
  )

  const activeLink = (link: string) => <a href={link} target={"_blank"} rel={"noreferrer"}>{link}</a>

  const infoDescriptions = {
    showerFlowRate: <>Amount of water which passes through the shower head per unit of time. In this case we
      are using number of litres per minute.</>,
    gasPrice: <>Assumes an annual gas demand of 12000 kWh and gas prices
      as per {activeLink("https://energysavingtrust.org.uk/about-us/our-data/")}</>,
    waterPrice: <>Water price will vary dependent on the area. Figure of £3.08 as per United
      Utilities {activeLink("https://www.unitedutilities.com/your-questions-answered/bills-payments/what-does-a-litre-or-cubic-metre-of-water-cost/")}</>,
    heatingSystemEfficiency: <>Heating system efficiency of 92% based on a boiler with an 'A' Energy rated performance.
      In use efficiency may differ - contact your boiler manufacturer for more information.</>,
    showerFlowTemperature: <>Shower flow temperature based on mean temperature position for NHS compliant
      Thermostatic Mixing Valves of 38°C. {activeLink("https://www.england.nhs.uk/wp-content/uploads/2021/05/Health_tech_memo_0401_supp_D08.pdf")}</>,
    specificWaterHeat: <>Specific heat of water of 4.19kJ/(kg•K) Isobaric Specific heat of water at 60°C.
          Source: {activeLink("https://www.engineeringtoolbox.com/")}</>,
    density: <>Density of water rounded to 1 from 0.98319 kg/l. Source: {activeLink("https://www.engineeringtoolbox.com/")}</>
  }

  const setAssumptions = () => {
    setTempShowerTemperature(targetShowerTemperature)
    setTempGasCost(gasCost)
    setTempWaterCost(waterCost)
    setTempBoilerEfficiency(boilerEfficiency)
    toggleEditing(true)
  }

  const applyAssumptions = () => {
    setTargetShowerTemperature(tempShowerTemperature)
    setGasCost(tempGasCost)
    setWaterCost(tempWaterCost)
    setBoilerEfficiency(tempBoilerEfficiency)
    toggleEditing(false)
  }

  const resetAssumptions = () => {
    toggleEditing(false)
  }

  const yellowInputStyle = {
    height: "48px",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#666F7A",
    background: "#FEF9EA",
    border: "1px solid #FEF9EA",
    borderRadius: "6px",
    padding: "12px 16px",
    display: "flex",
    alignItems: "center"
  }

  const selectStyle = {
    background: "#F5F6FA",
    border: "none",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#21272B"
  }

  const calculate = () => {
    const hotPercent = (targetShowerTemperature - coldWaterTemperature)/(hotWaterTemperature - coldWaterTemperature)
    const heatingDelta = hotWaterTemperature - coldWaterTemperature
    const hotFlow = hotPercent * showerFlowRate

    const waterPerShower = showerFlowRate * showerLength
    const heatingPerShower = showerLength / 60 * specificWaterHeat * density * heatingDelta * hotFlow * 60 / 3600
    const gasUsePerShower = heatingPerShower / 0.9
    const gasCostPerShower = gasCost * gasUsePerShower

    const showersPerYear = 365 * numberOfShowers
    const showersPerMonth = showersPerYear / 12
    const annualWaterUse = waterPerShower * showersPerYear / 1000
    const monthlyWaterUse = waterPerShower * showersPerMonth / 1000
    const dailyWaterUse = waterPerShower * numberOfShowers / 1000

    const annualGasCost = gasCostPerShower * showersPerYear
    const annualWaterCost = annualWaterUse * waterCost
    const monthlyGasCost = gasCostPerShower * showersPerMonth
    const monthlyWaterCost = monthlyWaterUse * waterCost
    const dailyGasCost = gasCostPerShower * numberOfShowers
    const dailyWaterCost = dailyWaterUse * waterCost

    const possibleHotFlow = hotPercent * possibleFlowRate
    const possibleWaterPerShower = possibleFlowRate * possibleLength
    const possibleHeatingPerShower = possibleLength / 60 * specificWaterHeat * density * heatingDelta * possibleHotFlow * 60 / 3600
    const possibleGasUsePerShower = possibleHeatingPerShower / 0.9
    const possibleGasCostPerShower = gasCost * possibleGasUsePerShower

    const possibleShowersPerYear = 365 * possibleNumberOfShowers
    const possibleShowersPerMonth = possibleShowersPerYear / 12

    const possibleAnnualWaterUse = possibleWaterPerShower * possibleShowersPerYear / 1000
    const possibleMonthlyWaterUse = possibleWaterPerShower * possibleShowersPerMonth / 1000
    const possibleDailyWaterUse = possibleWaterPerShower * possibleNumberOfShowers / 1000

    const possibleAnnualGasCost = possibleGasCostPerShower * possibleShowersPerYear
    const possibleAnnualWaterCost = possibleAnnualWaterUse * waterCost
    const possibleMonthlyGasCost = possibleGasCostPerShower * possibleShowersPerMonth
    const possibleMonthlyWaterCost = possibleMonthlyWaterUse * waterCost
    const possibleDailyGasCost = possibleGasCostPerShower * possibleNumberOfShowers
    const possibleDailyWaterCost = possibleDailyWaterUse * waterCost

    setAnnualGasCost(annualGasCost)
    setAnnualWaterCost(annualWaterCost)
    setMonthlyGasCost(monthlyGasCost)
    setMonthlyWaterCost(monthlyWaterCost)
    setDailyGasCost(dailyGasCost)
    setDailyWaterCost(dailyWaterCost)

    setPossibleAnnualGasCost(possibleAnnualGasCost)
    setPossibleAnnualWaterCost(possibleAnnualWaterCost)
    setPossibleMonthlyGasCost(possibleMonthlyGasCost)
    setPossibleMonthlyWaterCost(possibleMonthlyWaterCost)
    setPossibleDailyGasCost(possibleDailyGasCost)
    setPossibleDailyWaterCost(possibleDailyWaterCost)

    const annualSavings = Math.round(annualGasCost) + Math.round(annualWaterCost)
      - Math.round(possibleAnnualGasCost) - Math.round(possibleAnnualWaterCost)
    const monthlySavings = Math.round(monthlyGasCost) + Math.round(monthlyWaterCost)
      - Math.round(possibleMonthlyGasCost) - Math.round(possibleMonthlyWaterCost)
    const dailySavings = Math.round(dailyGasCost * 1000)/1000 + Math.round(dailyWaterCost * 1000)/1000
      - Math.round(possibleDailyGasCost * 1000)/1000 - Math.round(possibleDailyWaterCost * 1000)/1000

    setAnnualSavings(annualSavings)
    setMonthlySavings(monthlySavings)
    setDailySavings(dailySavings)
  }

  const savingsStatus = (savings: number) => savings > 0 ? <GrowthUp style={{marginLeft: "0.25rem"}} />
    : <GrowthDown style={{marginLeft: "0.25rem"}} />

  const assumptionsBlock = () => (<>
    {!isEditing ? <div>
        <div className={"calc-assumption-row"}>
          <span className={"calc-assumption-label"}>Assumptions</span>
          <span className={"calc-assumption-button"} onClick={() => setAssumptions()}>Edit assumptions</span>
        </div>
        <div className={"calc-assumption-row"}>
          <span>Price of gas {infoDropdown("Price of gas", infoDescriptions.gasPrice)}</span>
          <span>£{gasCost} per kWh</span>
        </div>
        <div className={"calc-assumption-row"}>
          <span>Price of water {infoDropdown("Price of water", infoDescriptions.waterPrice)}</span>
          <span>£{waterCost} per m<sup>3</sup></span>
        </div>
        <div className={"calc-assumption-row"}>
          <span>Heating system efficiency {infoDropdown("Heating system efficiency", infoDescriptions.heatingSystemEfficiency)}</span>
          <span>{boilerEfficiency}%</span>
        </div>
        <div className={"calc-assumption-row"}>
          <span>Shower flow temperature {infoDropdown("Shower flow temperature", infoDescriptions.showerFlowTemperature)}</span>
          <span>{targetShowerTemperature}°C</span>
        </div>
        <div className={"calc-assumption-row"}>
          <span>Specific heat of water {infoDropdown("Specific heat of water", infoDescriptions.specificWaterHeat)}</span>
          <span>{specificWaterHeat} kJ/(kg•K)</span>
        </div>
        <div className={"calc-assumption-row"}>
          <span>Density of water {infoDropdown("Density of water", infoDescriptions.density)}</span>
          <span>{density} kg/litre</span>
        </div>
      </div> :
      <div>
        <div className={"calc-assumption-row"}>
          <span className={"calc-assumption-label"}>Assumptions</span>
          <span>
              <span className={"calc-assumption-button"} onClick={() => resetAssumptions()}>Reset</span>
              <span className={"calc-assumption-button"} onClick={() => applyAssumptions()}>Save</span>
            </span>
        </div>
        <div className={"calc-assumption-row"}>
          <span>Price of gas {infoDropdown("Price of gas", infoDescriptions.gasPrice)}</span>
          <Input
            className={"calc-input"}
            value={tempGasCost}
            suffix={<>£/kWh</>}
            onChange={(e: any) => setTempGasCost(e.target.value)}
          />
        </div>
        <div className={"calc-assumption-row"}>
          <span>Price of water {infoDropdown("Price of water", infoDescriptions.waterPrice)}</span>
          <Input
            className={"calc-input"}
            value={tempWaterCost}
            suffix={<>£/m<sup>3</sup></>}
            onChange={(e: any) => setTempWaterCost(e.target.value)}
          />
        </div>
        <div className={"calc-assumption-row"}>
          <span>Heating system efficiency {infoDropdown("Heating system efficiency", infoDescriptions.heatingSystemEfficiency)}</span>
          <Input
            className={"calc-input"}
            value={tempBoilerEfficiency}
            suffix={<>%</>}
            onChange={(e: any) => setTempBoilerEfficiency(e.target.value)}
          />
        </div>
        <div className={"calc-assumption-row"}>
          <span>Shower flow temperature {infoDropdown("Shower flow temperature", infoDescriptions.showerFlowTemperature)}</span>
          <Input
            className={"calc-input"}
            value={tempShowerTemperature}
            suffix={"°C"}
            onChange={(e: any) => setTempShowerTemperature(e.target.value)}
          />
        </div>
        <div className={"calc-assumption-row"}>
          <span>Specific heat of water {infoDropdown("Specific heat of water", infoDescriptions.specificWaterHeat)}</span>
          <span>{specificWaterHeat} kJ/(kg•K)</span>
        </div>
        <div className={"calc-assumption-row"}>
          <span>Density of water {infoDropdown("Density of water", infoDescriptions.density)}</span>
          <span>{density} kg/litre</span>
        </div>
      </div>}
  </>)

  return <div>
    <HelmetMetaData
      title={"Livlet | Shower cost calculator"}
      description={"Livlet's shower cost calculator for homeowners"}
      image={"https://www.livlet.com/img/livletLogo.jpg"}
      keywords={"gas price, water price, shower, shower cost"} />
    <div className={"stripe-white"}>
      <div className={"container container-column"}>
        <h2 className={"page-header"}>Shower cost calculator</h2>
        <p style={{
          width: "100%",
          textAlign: "center",
          fontSize: "14px"}}>
          Calculate your yearly gas and water expenses in regards to showers served by gas boiler systems.</p>
      </div>
    </div>
    <div className={"calc-wrapper"}>
      <div className={"calc-section-wrapper"}>
        <div className={"calc-section-header"}>
          <SimulatorHome/> I have
        </div>
        <div className={"calc-upper-section"}>
          <div className={"calc-cell-wrapper"}>
            <div className={"calc-cell"}>
              <div className={"calc-field-label"}>Number of showers per day</div>
              <Input
                value={numberOfShowers}
                style={yellowInputStyle}
                onChange={(e: any) => setNumberOfShowers(e.target.value)}
              />
            </div>
            <div className={"calc-cell"}>
              <div className={"calc-field-label"}>Length of shower</div>
              <Input
                style={yellowInputStyle}
                className={"calc-input-yellow"}
                suffix={"mins"}
                value={showerLength}
                onChange={(e: any) => setShowerLength(e.target.value)}
              />
            </div>
          </div>
          <div className={"calc-cell-wrapper"}>
            <div className={"calc-cell"}>
              <div className={"calc-field-label"}>
                Shower flow rate {infoDropdown("Shower flow rate", infoDescriptions.showerFlowRate)}
              </div>
              <Input
                style={yellowInputStyle}
                className={"calc-input-yellow"}
                value={showerFlowRate}
                suffix={"l/min"}
                onChange={(e: any) => setShowerFlowRate(e.target.value)}
              />
            </div>
            <div className={"calc-cell"}>
              <Dropdown
                placement={"bottomRight"}
                overlay={<div className={"calc-dropdown"} style={{maxWidth: "500px", borderRadius: "0 0 8px 8px"}}>
                <p style={{fontWeight: 600}}>Shower flow rate</p>
                <p>Shower flow rates depends on the available water flow, on water pressure and the shower head's characteristics.</p>
                <p>The amount of pressure at your shower will depend on the height of the shower head vs the water supply,
                  whether you have a cold water tank, whether you have a water boosting system and will depend on the
                  resistance build up in your cold water pipework, valves and fittings.</p>
                <p>Shower heads will generally deliver higher flow rates at higher pressures and at higher diameters.</p>
                <p>An easy way to estimate is to see how long it takes to fill a 1 litre container. Divide the litre by
                  the seconds to get litres per second and then multiply by 60 to get litres per minute.</p>
                <p style={{fontWeight: 600, fontStyle: "italic"}}>For example, filling a 1 litre container in 3 seconds
                  would mean a flow rate equal to 0.33 l/s or 20 l/min.</p>
              </div>}><button className={"calc-shower-flow-button"}>
                How do I find out my shower's flow rate? <ArrowDown />
              </button></Dropdown>
            </div>
          </div>
          <div className={"calc-cell-wrapper showerhead-wrapper"} style={{marginTop: "1rem"}}>
            <div className={"calc-shower-cell"}>
              <img src={calcHandshower} alt={""} />
              Handshower<br/>~8 l/min
            </div>
            <div className={"calc-shower-cell"}>
              <img src={calcHeadshower} alt={""} />
              Headshower<br/>~14 l/min
            </div>
            <div className={"calc-shower-cell"}>
              <img src={calcRainshower} alt={""} />
              Rainshower<br/>~24 l/min
            </div>
          </div>
        </div>
        <div className={"calc-lower-section"}>
          <div className={"calc-assumption-row"}>
            <span className={"calc-result-label"}>{costTypeToShow} gas cost of showers</span>
            <span className={"calc-result-value"}>
              {costTypeToShow === "Annual" && Math.round(annualGasCost)}
              {costTypeToShow === "Monthly" && Math.round(monthlyGasCost)}
              {costTypeToShow === "Daily" && Math.round(dailyGasCost * 1000)/1000}
              {` £/${frequencyToShow}`}
            </span>
          </div>
          <div className={"calc-assumption-row"}>
            <span className={"calc-result-label"}>{costTypeToShow} water cost of showers</span>
            <span className={"calc-result-value"}>
              {costTypeToShow === "Annual" && Math.round(annualWaterCost)}
              {costTypeToShow === "Monthly" && Math.round(monthlyWaterCost)}
              {costTypeToShow === "Daily" && Math.round(dailyWaterCost * 1000)/1000}
              {` £/${frequencyToShow}`}
            </span>
          </div>
          <span className={"calc-assumptions-desktop"}>
            {assumptionsBlock()}
          </span>
        </div>
      </div>
      <div className={"calc-section-wrapper"}>
        <div className={"calc-section-header"}>
          <SimulatorImprovedHome /> What if I had
        </div>
        <div className={"calc-upper-section"}>
          <div className={"calc-field-label"}>Shower flow rate</div>
          <Input
            style={yellowInputStyle}
            className={"calc-input-yellow"}
            value={possibleFlowRate}
            suffix={"l/min"}
            onChange={(e: any) => setPossibleFlowRate(e.target.value)}
          />
          <div className={"calc-field-label"}>Length of shower</div>
          <Input
            style={yellowInputStyle}
            className={"calc-input-yellow"}
            suffix={"mins"}
            value={possibleLength}
            onChange={(e: any) => setPossibleLength(e.target.value)}
          />
          <div className={"calc-field-label"}>Number of showers per day</div>
          <Input
            value={possibleNumberOfShowers}
            style={yellowInputStyle}
            onChange={(e: any) => setPossibleNumberOfShowers(e.target.value)}
          />
          <div className={"calc-right-shift-row"} style={{marginTop: "24px"}}>
            <div className={"calc-select-wrapper"}>
              <span className={"calc-select-label"}>Show</span>
              <Select
                className={"calc-select"}
                style={selectStyle}
                bordered={false}
                defaultValue={costTypeToShow}
                suffixIcon={<ArrowDownGray style={{paddingBottom: "9px", display: "flex", flexShrink: 0}} />}
                onChange={(e) => {
                  setCostTypeToShow(e)
                  e === "Annual" && setFrequencyToShow("yr")
                  e === "Monthly" && setFrequencyToShow("mon")
                  e === "Daily" && setFrequencyToShow("day")
                }}
              >
                <Select.Option value={"Annual"}>Annual costs</Select.Option>
                <Select.Option value={"Monthly"}>Monthly costs</Select.Option>
                <Select.Option value={"Daily"}>Daily costs</Select.Option>
              </Select>
            </div>
          </div>
          <div className={"calc-right-shift-row"} style={{marginTop: "42px"}}>
            <button className={"calc-calculate-button"} onClick={() => calculate()}>Calculate</button>
          </div>
        </div>
        <div className={"calc-lower-section"}>
          <div className={"calc-assumption-row"}>
            <span className={"calc-result-label"}>{costTypeToShow} gas cost of showers</span>
            <span className={"calc-result-value"}>
              {costTypeToShow === "Annual" && Math.round(possibleAnnualGasCost)}
              {costTypeToShow === "Monthly" && Math.round(possibleMonthlyGasCost)}
              {costTypeToShow === "Daily" && Math.round(possibleDailyGasCost * 1000)/1000}
              {` £/${frequencyToShow}`}
            </span>
          </div>
          <div className={"calc-assumption-row"}>
            <span className={"calc-result-label"}>{costTypeToShow} water cost of showers</span>
            <span className={"calc-result-value"}>
              {costTypeToShow === "Annual" && Math.round(possibleAnnualWaterCost)}
              {costTypeToShow === "Monthly" && Math.round(possibleMonthlyWaterCost)}
              {costTypeToShow === "Daily" && Math.round(possibleDailyWaterCost * 1000)/1000}
              {` £/${frequencyToShow}`}
            </span>
          </div>
          <div className={"calc-right-shift-row"}>
            <span className={"calc-saving-label"}>Possible saving:</span>
            <span className={"calc-saving-value"}>
              {"£ "}
              {costTypeToShow === "Annual" && annualSavings}
              {costTypeToShow === "Monthly" && monthlySavings}
              {costTypeToShow === "Daily" && Math.round(dailySavings * 1000)/1000}
              {costTypeToShow === "Annual" && savingsStatus(annualSavings)}
              {costTypeToShow === "Monthly" && savingsStatus(monthlySavings)}
              {costTypeToShow === "Daily" && savingsStatus(dailySavings)}
            </span>
          </div>
          <span className={"calc-assumptions-mobile"}>
            {assumptionsBlock()}
          </span>
        </div>
      </div>
    </div>
  </div>
}

export default ShowerCalculator