import React, {useState} from "react";
import styled from "styled-components";
import { ReactComponent as PopupCross } from "../images/PopupCross.svg";
import { ReactComponent as PopupLogo } from "../images/PopupLogo.svg";
import {ReactComponent as PopupArrow} from "../images/PopupArrow.svg";
import popupImage from "../images/PopupImage.png";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import {getEmailPreferences, setEmailPreferences} from "../localStorage";
import envConfig from "../config";

const SubscriptionPopup = () => {
  const [form] = Form.useForm();
  const [isSubmitted, toggleSubmitted] = useState(false)
  const [isChecked, toggleChecked] = useState(false)
  const [showError, toggleError] = useState(false)
  const [showPopup, togglePopup] = useState(true)
  const showPopupStorage = getEmailPreferences();

  function handleSubmit(e: any) {
    const {email} = e;
    if (!isChecked) {
      toggleError(true);
      return;
    } else {
      toggleError(false);
      fetch(`${envConfig.API_URL}/api/user/newsletterSignup`, {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          tipsMailing: true
        }),
      }).then(() => {
        toggleSubmitted(true);
        setTimeout(() => {
          setEmailPreferences("closed")
          togglePopup(false)
        }, 10000);
      })
    }
  }

  const openPopup = () => <PopupWindow className="animate__animated animate__bounce">
    <PopupLogo />
    <CrossButton onClick={() => {
      setEmailPreferences("closed")
      togglePopup(false)
    }}><PopupCross /></CrossButton>
    <span className={"popup-title"}>Do you want to reduce your home bills?</span>
    <span className={"popup-description"}>Livlet is here to help you save money, and we'd love to share our top tips
      for reducing household costs with you. Enter your email address below and we'll send it across straight away.</span>
    <img alt={""} src={popupImage} />
    <div className={"popup-form"}>
      <Form
        className={"email-form"}
        form={form}
        name="sendEmail"
        onFinish={handleSubmit}
      >
        <Form.Item
          key={"email"}
          name={"email"}
          rules={[
            {
              required: true,
              pattern: /\S+@\S+\.\S+/,
              message: "Please provide a valid email address.",
            }
          ]}
          style={{width: "100%", marginBottom: "0.75rem"}}
        >
          <Input
            className={"email-input"}
            autoComplete={"off"}
            style={{fontSize: "0.875rem", lineHeight: "1.125rem"}}
            name={"email"}
            type={"text"}
            placeholder={"Email address"}
          />
        </Form.Item>
        <Button className={"email-arrow-button"} style={{height: "1.75rem"}} htmlType={"submit"}><PopupArrow /></Button>
      </Form>
      <div className={"email-form-consent"}>
        <input type={"checkbox"} checked={isChecked} onClick={() => {
          toggleChecked(!isChecked)
          isChecked && toggleError(false)
        }}/>
        <div style={{fontSize: "0.875rem", lineHeight: "1.125rem"}}>
          By sharing your email you agree to our <b>Privacy Policy</b> and <b>Terms and Conditions</b>.
          {showError && <div style={{color: "#EFC45D", marginTop: "0.75rem", fontWeight: 600}}>
              Please agree to our Privacy Policy and Terms and Conditions to subscribe. You may opt out at any time.
          </div>}
        </div>
      </div>
    </div>
  </PopupWindow>

  const closedPopup = () => <PopupWindow>
    <PopupLogo />
    <CrossButton onClick={() => {
      setEmailPreferences("closed")
      togglePopup(false)
    }}><PopupCross /></CrossButton>
    <span className={"popup-title"}>We're sending you our top 10 suggestions now, they should be with you in moments!</span>
  </PopupWindow>

  return (showPopup && showPopupStorage!== "closed") ? (isSubmitted ? closedPopup() : openPopup()) : <></>
}

const PopupWindow = styled.div`
  position: fixed;
  left: 10px;
  bottom: 70px;
  z-index: 100;
  width: 26rem;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  gap: 1rem;
 
  background: #FFFFFF;
  border: 1px solid #DAE0E6;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #21272B;
  transition: .5s all ease; 
  
  .popup-title {
    font-weight: 600;
    opacity: 0.8;
  }
  
  .popup-description {
    font-weight: 400;
    opacity: 0.8;
  }
  
  img { max-width: 100%; }
  
  .popup-form {
    padding: 1rem 1rem 0.75rem;
    gap: 0.75rem;
    background: #009966;
    border-radius: 8px;
    
    font-weight: 400;
    font-size: 0.875rem!important;
    line-height: 1.125rem!important;
    color: #FFFFFF;
  }
  
  .ant-form-item-explain-error { 
    color: #EFC45D!important; 
    font-weight: 600!important; 
  }
  
  @media (max-height: 800px) {
    img { display: none; }
  }
  
  @media (max-width: 425px) {
    width: 20rem;
  }
`

const CrossButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  transition: .5s all ease;
  border-radius: 4px;
  height: 2rem;
  width: 2rem;
  border: 1px solid white;
  
  svg path { stroke: #45655E; }
  
  &:hover {
    background: #EAF3FC;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  &:hover svg path { stroke: #009966; }
`

export default SubscriptionPopup