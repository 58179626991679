import React, {lazy, Suspense} from "react";
import {NavLink, useLocation} from "react-router-dom";
import SuspensePlaceholder from "./SuspensePlaceholder";
import envConfig from "../config";
import {ReactComponent as LivletLogo} from "../images/livlet-logo-header.svg";
import {ReactComponent as CloseIconMobile} from "../images/CloseIconMobile.svg";
import styled from "styled-components";
const Drawer = lazy(() => import("antd/es/drawer"));

type Props = {
  showMenu: boolean;
  toggleMenu: (arg: boolean) => void;
}

const MobileMenu = (props: Props) => {
  const {showMenu, toggleMenu} = props;
  const location = useLocation();
  const currentPage = location.pathname;
  function closeMenu() { toggleMenu(false) }

  return (
    <Suspense fallback={<SuspensePlaceholder />}>
      <DrawerStyled
        width={"100%"}
        closeIcon={<></>}
        title={<div className={"header-block-mobile"}>
          <a href={"/"} className={"logo"} role="img" aria-label="Livlet full colour logo with symbol and wordmark"><LivletLogo /></a>
          <span style={{display: "flex", alignItems: "center", columnGap: "1rem"}}>
          {/*<a className={"navbar-sign-up button-header"} href={`${envConfig.WEBAPP_URL}/register`} target={"_blank"} rel={"noreferrer"}><button*/}
          {/*  className={"button-green button-cta"}*/}
          {/*  style={{fontSize: "14px", padding: "0 0.5rem"}}>Sign-up free</button></a>*/}
          <span className={"mobile-menu-icon"} style={{
            width: "2rem", height: "2rem", display: "flex", justifyContent: "center", alignItems: "center"
          }} onClick={() => toggleMenu(false)}><CloseIconMobile /></span>
        </span>
        </div>}
        placement={"right"}
        closable={true}
        onClose={closeMenu}
        visible={showMenu}
      >
        <NavLink exact to={"/"} onClick={closeMenu} className={"menu-item-mobile"}
                 activeClassName={"highlight-green"}>Home</NavLink>
        {/*<NavLink to={"/features"} onClick={closeMenu} className={"menu-item-mobile"}*/}
        {/*         activeClassName={"highlight-green"}>Features</NavLink>*/}
        {/*<NavLink to={"/pricing"} onClick={closeMenu} className={"menu-item-mobile"}*/}
        {/*         activeClassName={"highlight-green"}>Pricing</NavLink>*/}
        <NavLink to={"/news"} onClick={closeMenu}
                 className={`menu-item-mobile ${currentPage.includes("guidance") && "highlight-green"}`}
                 activeClassName={"highlight-green"}>News</NavLink>
        {/*<NavLink to={"/partners"} onClick={closeMenu} className={"menu-item-mobile"}*/}
        {/*         activeClassName={"highlight-green"}>Our partners</NavLink>*/}
        {/*<NavLink exact to={"/about-us"} onClick={closeMenu} className={"menu-item-mobile"}*/}
        {/*         activeClassName={"highlight-green"}>About us</NavLink>*/}
        <a href={`${envConfig.WEBAPP_URL}/login`} target={"_blank"} rel={"noreferrer"}
           style={{marginTop: "1rem", paddingBottom: 0}}
           className={"menu-item-mobile no-border navbar-login"}>
          <LoginButton>Login</LoginButton>
        </a>
        {/*<a href={`${envConfig.WEBAPP_URL}/register`} target={"_blank"} rel={"noreferrer"} className={"menu-item-mobile no-border navbar-sign-up"}>*/}
        {/*  <SignUpButton>Sign-up free</SignUpButton>*/}
        {/*</a>*/}
      </DrawerStyled>
    </Suspense>
  )
}

const DrawerStyled = styled(Drawer)`
  .ant-drawer-header {
    width: 100%;
    height: 4.5rem;
    padding: 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F5F5F5;
  }
  
  .ant-drawer-body { padding: 0; }
`

const LoginButton = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #000000;
`

const SignUpButton = styled(LoginButton)`
  background: #009966;
  border: none;
  color: #FFFFFF;
`

export default MobileMenu