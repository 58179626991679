import React, {lazy, Suspense, useEffect, useState} from 'react';
import './App.css';
import 'antd/dist/antd.compact.min.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MobileMenu from "./components/MobileMenu";
import ScrollToTop from './components/ScrollToTop';
import Home from './screens/Home';
import SuspensePlaceholder from "./components/SuspensePlaceholder";
import ShowerCalculator from "./screens/ShowerCalculator";
import envConfig from './config';
import FirstPromo from "./screens/FirstPromo";
import moment from "moment";
import {useCookies} from "react-cookie";
import Partners from "./screens/Partners";
import Guidance from "./screens/Guidance";

const Features = lazy(() => import("./screens/Features"));
const AboutUs = lazy(() => import("./screens/AboutUs"));
const Blog = lazy(() => import("./screens/Blog"));
const BlogPost = lazy(() => import("./screens/BlogPost"));
const Pricing = lazy(() => import("./screens/Pricing"));
const FAQ = lazy(() => import("./screens/FAQ"));
const EnergyMethods = lazy(() => import("./screens/EnergyMethods"));

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(['livlet-referral']);
  const [showMenu, toggleMenu] = useState(false)

  useEffect(() => {
    console.log("window.location.hostname="+window.location.hostname)
    if (window.location.hostname === "colmoregaskell.livlet.com") {
      // subscriptionId = -2
      setCookie('livlet-referral',
        "eyJhbGciOiJIUzI1NiJ9.eyJ0ZW5hbnRJZCI6LTIsImp0aSI6IjAifQ.nIeczUty1k4eIz9DAh8uJJuoC8D2tzESlBwvpOCn_ZQ",
        { path: '/', expires: moment().add(3, 'day').toDate(), domain: '.livlet.com' });
    }
    if (window.location.hostname === "london.livlet.com") {
      // subscriptionId = -3
      setCookie('livlet-referral',
        "eyJhbGciOiJIUzI1NiJ9.eyJ0ZW5hbnRJZCI6LTMsImp0aSI6IjAifQ.Bks2L0wNyfZVLZILNfm0uOEuH8nx70Ny13Z31Dvqcns",
        { path: '/', expires: moment().add(3, 'day').toDate(), domain: '.livlet.com' });
    }
    if (window.location.hostname === "rightathome.livlet.com") {
      // subscriptionId = -4
      setCookie('livlet-referral',
        "eyJhbGciOiJIUzI1NiJ9.eyJ0ZW5hbnRJZCI6LTQsImp0aSI6IjAifQ.JGleXvTEXEHNR13Hs5b0u9docsg7U4jbhxh6SuPUTWc",
        { path: '/', expires: moment().add(3, 'day').toDate(), domain: '.livlet.com' });
    }
    if (window.location.hostname === "keytek.livlet.com") {
      // subscriptionId = -5
      setCookie('livlet-referral',
        "eyJhbGciOiJIUzI1NiJ9.eyJ0ZW5hbnRJZCI6LTUsImp0aSI6IjAifQ.oVHjd1LkJBwPT9OHuB-wUxOmirITPAGW08PDlQ0Nbes",
        { path: '/', expires: moment().add(3, 'day').toDate(), domain: '.livlet.com' });
    }
    if (window.location.hostname === "instagroup.livlet.com") {
      // subscriptionId = -6
      setCookie('livlet-referral',
        "eyJhbGciOiJIUzI1NiJ9.eyJ0ZW5hbnRJZCI6LTYsImp0aSI6IjAifQ.YbReETIbGAItUApmPrJI7n7jCmoSC0TNFxBU9K42yy4",
        { path: '/', expires: moment().add(3, 'day').toDate(), domain: '.livlet.com' });
    }
  }, []);

  return (
    <div className="App" style={{marginTop: "72px"}}>
      <GoogleReCaptchaProvider reCaptchaKey={envConfig.GOOGLE_RECAPTCHA_SITE_KEY}>
        <Router>
          <ScrollToTop/>
          <MobileMenu showMenu={showMenu} toggleMenu={toggleMenu} />
          <Header toggleMenu={toggleMenu} />
            <Suspense fallback={<SuspensePlaceholder />}>
              <Switch>
                <Route exact path={"/"} component={Home} />
                <Route exact path={"/save-money-home"} component={Home} />
                <Route exact path={"/organize-your-household"} component={Home} />
                <Route exact path={"/advice-guidance"} component={Home} />
                <Route exact path={"/avoid-home-emergencies"} component={Home} />
                {/*<Route path={"/livlet-early-bird"} component={FirstPromo} />*/}
                {/*<Route exact path={"/features"} component={Features} />*/}
                {/*<Route exact path={"/pricing"} component={Pricing} />*/}
                <Route exact path={"/faq"} component={FAQ} />
                {/*<Route exact path={"/about-us"} component={AboutUs} />*/}
                {/*<Route exact path={"/partners"} component={Partners} />*/}
                <Route exact path={"/news"} component={Blog} />
                {/*<Route exact path={"/guidance"} component={Guidance} />*/}
                <Route exact path={"/faq/energy-bills-co2-savings"} component={EnergyMethods} />
                <Route path={"/news/:id"} component={BlogPost} />
                {/*<Route path={"/guidance/:id"} component={BlogPost} />*/}
                <Route exact path={"/shower-calculator"} component={ShowerCalculator} />
                <Route path={"/adduser"} render={() => {
                  if (window.location.hostname === "new.livlet.com") {
                    // @ts-ignore
                    window.location = "http://test-app.livlet.com/keytek"
                  }
                  if (window.location.hostname === "keytek.livlet.com") {
                    // @ts-ignore
                    window.location = "http://uk.livlet.com/keytek"
                  }
                  return <SuspensePlaceholder />
                }} />
                <Redirect from={"*"} to={"/"} />
              </Switch>
            </Suspense>
          <Footer />
        </Router>
    </GoogleReCaptchaProvider>
  </div>
  );
}

export default App;
