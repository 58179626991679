import React, {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import { ReactComponent as LivletLogo } from "../images/livlet-logo-header.svg";
import {MenuOutlined} from "@ant-design/icons";
import envConfig from "../config";

type Props = {
  toggleMenu: (arg: boolean) => void;
}

const Header = (props: Props) => {
  const {toggleMenu} = props;
  const [scrollPoint, setScrollPoint] = useState(window.scrollY)
  const location = useLocation();
  const currentPage = location.pathname;

  useEffect(() => {
    function handleScroll() {
      setScrollPoint(window.scrollY)
    }
    window.addEventListener('scroll', handleScroll)
    return () => { window.addEventListener('scroll', handleScroll) }
  })

  return (
    <div className={"header"} style={{borderBottom: scrollPoint < 1 ? "none" : "1px solid #DAE0E6"}}>
      <div className={"header-block-mobile"}>
        <a href={"/"} className={"logo"} role="img" aria-label="Livlet full colour logo with symbol and wordmark"><LivletLogo /></a>
        <span style={{display: "flex", alignItems: "center", columnGap: "1rem"}}>
          {/*<a className={"navbar-sign-up"} href={`${envConfig.WEBAPP_URL}/register`} target={"_blank"} rel={"noreferrer"}><button*/}
          {/*  className={"button-green button-cta button-header"}*/}
          {/*  style={{fontSize: "14px", padding: "0 0.5rem"}}>Sign-up free</button></a>*/}
          <span className={"mobile-menu-icon"} onClick={() => toggleMenu(true)}><MenuOutlined /></span>
        </span>
      </div>
      <div className={"header-block"}>
          <div className={"livlet-logo"}>
            <NavLink to={"/"}><LivletLogo /></NavLink>
          </div>
          <div style={{display: "flex", alignItems: "center", flexGrow: 2}}>
            {/*<NavLink to={"/features"} className={"menu-item"} activeClassName={"menu-item-active"}>Features</NavLink>*/}
            {/*<NavLink to={"/pricing"} className={"menu-item"} activeClassName={"menu-item-active"}>Pricing</NavLink>*/}
            <NavLink to={"/news"} className={`menu-item ${currentPage.includes("guidance") && "menu-item-active"}`}
                     activeClassName={"menu-item-active"}>News</NavLink>
            {/*<NavLink to={"/partners"} className={"menu-item"} activeClassName={"menu-item-active"}>Our partners</NavLink>*/}
            {/*<NavLink to={"/about-us"} className={"menu-item"} activeClassName={"menu-item-active"}>About us</NavLink>*/}
          </div>
          <div style={{display: "flex", alignItems: "center"}}>
            <a className={"navbar-login"} href={`${envConfig.WEBAPP_URL}/login`} target={"_blank"} rel={"noreferrer"}>
              <button className={"login-button"} style={{marginRight: "1.25rem"}}>Login</button>
            </a>
            {/*<a className={"navbar-sign-up"} href={`${envConfig.WEBAPP_URL}/register`} target={"_blank"} rel={"noreferrer"}>*/}
            {/*  <button className={"free-trial-button button-green"}>Sign-up free</button>*/}
            {/*</a>*/}
          </div>

      </div>
    </div>
  )
}

export default Header