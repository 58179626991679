import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import TagManager from 'react-gtm-module';
import {CookiesProvider} from "react-cookie";
// import {getMarketingCookies} from "./localStorage";

const tagManagerArgs = {
  gtmId: window.location.hostname === "new.livlet.com" ? "GTM-TJCKD9J" : "GTM-WGWLXQL"
}

// if (getMarketingCookies() === "true") {
TagManager.initialize(tagManagerArgs)
// }

ReactDOM.render(
  <CookiesProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </CookiesProvider>,
  document.getElementById('root')
);